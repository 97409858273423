// Styles for header.
// -----------------------------------------------------------------------------


header {
  position: fixed;
  z-index: 1000;
  width: 100%;
  background-color: white;

  &.scrolled{
    -moz-box-shadow: 2px 0 3px rgba(0, 0, 0, 0.46);
    box-shadow: 2px 0 3px rgba(0, 0, 0, 0.46);
    -webkit-transition: width 1s; /* Safari */
    transition: width 1s;
    transition-timing-function: linear;
  }

  #logo {
    position: absolute;
    top: 25px;
    left: 50px;
    height: auto;

    img {
      width: 150px;
    }

    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
      left: 10px;
    }

    @media (max-width: $screen-xs-max) {
      left: 20px;
      top: 12px;
	  z-index: 1000;
      img {
        width: 96px;
        height: 30px;
      }
    }
  }

  #nav-container {
    @media (max-width: $screen-xs-max) {
      height: 54px;
      text-align: right;
    }

    #header-top {
      margin-right: 35px;
      float: right;

      @media (max-width: $screen-sm-max) {
        margin-right: 25px;
      }

      @media (max-width: $screen-xs-max) {
        position: absolute;
        z-index: 999;
        top: 50px;
        left: 10px;
	&.collapsed{

	  .menu-link-contact, .menu-link-careers, .menu-link-order-supplies {
	    display: none;
	  }
	  position: absolute;
	  top: 0;
	  right: 62px;
	  float: none;
	  padding: 0;
	  margin-right: 0;

	}
      }
      .block-menu {
        display: inline-block;

	ul {
          position: relative;
          padding-top: 5px;
          padding-bottom: 12px;
          vertical-align: middle;

          @media (max-width: $screen-xs-max) {
            padding-top: 13px;
          }

	  li {
	    display: inline-block;
            padding: 0;
            vertical-align: middle;

            &.menu-link-order-supplies {
              position: absolute;
              top: 52px;
              right: 15px;
              padding: 0;
              z-index: $zindex-navbar + 5;

              @media (min-width: $screen-sm-min) and (max-width: 890px) {
                position: static;
                top: auto;
                right: auto;
              }

              @media (max-width: $screen-xs-max) {
                top: 75px;
                left: 25px;
                right: auto;
              }

              a {
                padding: 4.5px 30px 4.5px 15px;
                color: white !important;
                background-color: $orange !important;
                box-shadow: none !important;


                &:hover {
                  background-color: $orange-light !important;
                }

                &:active, &:focus {
                  color: $grey-light !important;
                  background-color: $orange-light !important;
                }
              }
            }

            &.customer-login, &.menu-link-my-account {
              a {
                padding-left: 30px;
                @include menu-icon('/sites/all/themes/spectra/assets/images/menu_icons/customer_login_icon.svg');

                @media (max-width: $screen-xs-max) {
                  width: 20px;
                  height: 30px;
                  padding: 0;
                  font-size: 0;
                }
              }
            }

            &.search-icon {
              @include menu-icon('/sites/all/themes/spectra/assets/images/menu_icons/search-icon.svg');
              font-size: 0;
              width: 22px;
              height: 30px;
              cursor: pointer;
            }

            @media (max-width: $screen-xs-max) {
              /* &:not(.customer-login):not(.menu-link-my-account):not(.menu-link-log-out):not(.search-icon) {
                display: none;
              }*/
            }

	    a {
              padding-top: 5px;
              padding-bottom: 5px;

              @media (max-width: $screen-xs-max) {
                line-height: 20px;
              }

	      &:hover, &:focus {
		background-color: #FFF;
	      }
	    }
	  }
	}
      }

      &:not(.collapsed) {
        width: calc(100% - 62px);

        text-align: left;

        .block {
          ul {
            position: static;
          }
        }
        .customer-login, .menu-link-my-account, .menu-link-log-out, .search-icon {
          display: none;
        }

        .menu-link-order-supplies {
          display: inline !important;
        }
        ul {
          li {
            &.menu-link-contact,&.menu-link-career{
	      display: block!important;
            }
          }
        }
      }

      #block-search-form {
        display: inline-block;
        position: relative;

        #search-block-form {
          display: none;
          background-color: white;
          position: absolute;
          top: -20px;
          right: 40px;
          width: 474px;
          height: 34px;
          z-index: 1001;

          @media (max-width: $screen-xs-max) {
            padding-right: 20px;
            width: 220px;
          }

          .overlay {
            content: " ";
            position: fixed;
            width: 100vw;
            height: 100vh;
            z-index: -1;
            top: 0px;
            right: 0px;
          }

          .form-item-search-block-form, .form-actions {
            float: none;
            display: inline-block;
          }

          .form-item-search-block-form {
            input {
              width: 350px;

              @media (max-width: $screen-xs-max) {
                width: 200px;
              }
            }
          }

          .form-actions {
            width: auto;
            padding: 0;
            margin-left: 10px;

            @media (max-width: $screen-xs-max) {
              display: none;
            }

            input {
              margin: 0;
              position:relative;
              width: auto;
            }
          }
        }
      }
    }

    .navbar {
      clear: both;
      min-height: 0;
      margin-bottom: 0;
      z-index: $zindex-navbar;
      border: none;
      background: none;


      @media (min-width: $screen-md-min) {
        margin-left: 220px;
      }

      @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        margin-left: 160px;
      }

      @media (max-width: $screen-xs-max) {
        position: absolute;
        top: 0;
        right: 0;
        float: none;
        text-align: left;
        vertical-align: middle;
      }

      .navbar-toggle {
        height: 54px;
        margin: 0;
        padding-right: 20px;
        padding-left: 20px;
        border: 0;

        &:hover, &:active, &:focus {
          background: none;
        }

        .icon-bar {
          background-color: $orange;
          transition: all 200ms ease-in-out;

          &:hover {
            background-color: $orange-light;
          }
        }

        &:not(.collapsed) {
          .icon-bar {
            &:nth-child(2) {
	      transform: translateY(6px) rotate(45deg);
            }

	    &:nth-child(3) {
	      transform: rotate(180deg);
	      opacity: 0;
	    }

	    &:last-child {
	      transform: translateY(-6px) rotate(-45deg);
	    }
          }
        }
      }

      #navbar-collapse {
        width: 100vw;
		
		
        @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
          padding: 0;
        }

        #main-menu {
          width: 100%;
          padding-right: 35px;

          & > li {
            position: relative;
            padding: 10px 14px 25px 14px;

            @media (max-width: $screen-md-max) {
              padding-left: 7px;
              padding-right: 7px;
            }

            & > a:not(.btn) {
              padding: 0;
              color: $grey;
              background: none;

              &:hover, &.active-trail {
                color: $orange;
              }

              &:active {
                color: $grey-dark;
              }

              .fa-caret-down {
                display: none;
              }
            }
			&.divisions {
			      display: none;
			}
            @media (min-width: $screen-sm-min) {
              &.divisions {
                ul {
                  padding: 14px 10px;

                  li {
                    a {
                      font-size: 0;
                      width: 74px;
                      height: 23px;
                      background-size: 74px 23px;

                      &.laboratories {
                        @include menu-icon('/sites/all/themes/labs/logo.svg');
                      }

                      &.diagnostics {
                        @include menu-icon('/sites/all/themes/diag/logo.svg');
                      }
                    }
                  }
                }
              }
            }

            ul.dropdown-menu {
              position: absolute;
              background-color: $grey-ultra-light;
              border: none;
              left: 50%;
              transform: translateX(-50%);
              border-radius: 0 0 10px 10px;
              box-shadow: none;
              min-width: 0;

              li {
                a {
                  background-color: $grey-ultra-light;
                  color: $grey;
                  padding: 6.5px 15px;

                  &:hover {
                    color: $orange;
                  }

                  &:active {
                    color: $grey-dark;
                  }
                }

                &:last-child {
                  a {
                    border-radius: 0 0 10px 10px;
                  }
                }
              }
            }
          }

          & > li:last-child ul {
            @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
              left: auto;
              right: 0;
              transform: translateX(0);
            }
          }
        }
      }

      @media (max-width: $screen-xs-max) {
        #navbar-collapse.in, #navbar-collapse.collapsing {
          position: relative;
          background-color: white;
          border-top: none;
          padding: 0 25px 25px 25px;
          border-top: 125px solid white;
		  padding-bottom: 100px;
		  
          #main-menu {
            margin: 0;
            padding: 0;

            & > li {
              padding: 0;

              & > a:not(.btn) {
                line-height: 30px;
                font-size: 20px;
                font-weight: 500;
                color: $brand-color;
              }

              ul {
                display: block;
                position: static;
                float: none;
                transform: translateX(0);
                background: none;

                li {
                  a {
                    padding: 0;
                    line-height: 30px !important;
                    background: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: $screen-sm-min) {
  &.noscroll.navbar-collapse-in {
    header {
      #logo {
        left: 20px;
        top: 12px;
        z-index: 1000;
        img {
          width: 96px;
          height: 30px;
        }
      }

      #nav-container {
        height: 54px;
        text-align: right;

        #header-top {
          position: absolute;
          z-index: 999;
          top: 50px;
          left: 10px;

          &.collapsed {
	    .menu-link-contact, .menu-link-careers, .menu-link-order-supplies {
	      display: none;
	    }
	    position: absolute;
	    top: 0;
	    right: 62px;
	    float: none;
	    padding: 0;
	    margin-right: 0;
	  }

          .block-menu {
            ul {
              @media (max-width: $screen-xs-max) {
                padding-top: 13px;
              }

              li {
                &.menu-link-order-supplies {
                  position: absolute;
                  top: 75px;
                  left: 25px;
                  right: auto;
                }

                &.customer-login, &.menu-link-my-account {
                  a {
                    width: 20px;
                    height: 30px;
                    padding: 0;
                    font-size: 0;
                  }
                }
              }
            }
          }
        }

        .navbar {
          position: absolute;
          top: 0;
          right: 0;
          float: none;
          text-align: left;
          vertical-align: middle;

          .navbar-header {
            float: none;

            button {
              display: block;
            }
          }

          #navbar-collapse.in, #navbar-collapse.collapsing {
            position: relative;
            background-color: white;
            border-top: none;
            padding: 0 25px 25px 25px;
            border-top: 125px solid white;
            overflow-y: scroll !important;

            #main-menu {
              margin: 0;
              padding: 0;

              & > li {
                padding: 0;
                float: none;

                & > a:not(.btn) {
                  line-height: 30px;
                  font-size: 20px;
                  font-weight: 500;
                  color: $brand-color;
                }

                &.divisions {
		        ul {
                    padding: 5px 0;

                    li {
                      a {
                        font-size: 14px;
                        background: none;
                      }
                    }
                  }
                }

                ul {
                  display: block;
                  position: static;
                  float: none;
                  transform: translateX(0);
                  background: none;

                  li {
                    a {
                      padding: 0;
                      line-height: 30px !important;
                      background: none;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
