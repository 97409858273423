// Styles for footer.
// -----------------------------------------------------------------------------
footer {
    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        .panels-bootstrap-column:nth-child(4) {
            clear: both;
        }
    }

    .pane-main-menu, .pane-user-menu {
        @media (max-width: $screen-xs-max) {
            display: none;
        }

        h2 {
            margin: 0;
            font-size: 16px;
            padding: 5px 0;
            line-height: 20px;

            a {
                color: $brand-color;
                font-weight: 500;

                &:hover {
                    color: $orange;
                }
            }
        }
        ul.nav-pills.nav-stacked {
            margin-bottom: 20px;
            li {
                a {
                    padding: 5px 0;
                    background: none !important;
                    color: $grey;
                    text-align: left;

                    &:hover {
                        color: $orange;
                    }

                    &.active {
                      box-shadow: none;
                    }
                }

                &.search-icon {
                  display: none;
                }
            }
        }
    }

    .pane-user-menu {
        ul.nav-pills.nav-stacked li a {
            color: $brand-color;
            font-weight: 500;
            padding: 4.8px 0;
        }
    }

    .copyright {
        @media (max-width: $screen-xs-max) {
            float: none !important;
            text-align: center;
        }
    }

    ul#legal-menu {
        padding: 0;

        @media (max-width: $screen-xs-max) {
            margin-top: 20px;
            float: none !important;
            text-align: center;
        }

        li {
            list-style-type: none;
            display: inline-block;

            &:not(:last-child):after {
                content: "|";
            }

            a {
                color: $grey;
                padding-right: 5px;


                &:hover {
                    color: $orange;
                }
            }
        }
    }
}