// @file
// SASS partials import
// -----------------------------------------------------------------------------

// Bootstrap global partials.
// -----------------------------------------------------------------------------
@import "base/variables";
@import "bootstrap/variables";
@import "bootstrap/mixins";

// Bootswatch partial
// -----------------------------------------------------------------------------
@import "bootswatch/variables";

// Compass
// -----------------------------------------------------------------------------
@import "~compass-mixins";

// labs global partials.
// -----------------------------------------------------------------------------
@import "radix/mixins";
@import "base/mixins";
@import "base/helpers";

// Bootstrap reset and dependencies
// -----------------------------------------------------------------------------
@import "bootstrap/normalize";
@import "bootstrap/print";
@import "bootstrap/glyphicons";

// Bootstrap core
// -----------------------------------------------------------------------------
@import "bootstrap/scaffolding";
@import "bootstrap/type";
@import "bootstrap/code";
@import "bootstrap/grid";
@import "bootstrap/tables";
@import "bootstrap/forms";
@import "bootstrap/buttons";

// Bootstrap components
// -----------------------------------------------------------------------------
@import "bootstrap/component-animations";
@import "bootstrap/dropdowns";
@import "bootstrap/button-groups";
@import "bootstrap/input-groups";
@import "bootstrap/navs";
@import "bootstrap/navbar";
@import "bootstrap/breadcrumbs";
@import "bootstrap/pagination";
@import "bootstrap/pager";
@import "bootstrap/labels";
@import "bootstrap/badges";
@import "bootstrap/jumbotron";
@import "bootstrap/thumbnails";
@import "bootstrap/alerts";
@import "bootstrap/progress-bars";
@import "bootstrap/media";
@import "bootstrap/list-group";
@import "bootstrap/panels";
@import "bootstrap/responsive-embed";
@import "bootstrap/wells";
@import "bootstrap/close";

// Bootstrap components w/ JavaScript
// -----------------------------------------------------------------------------
@import "bootstrap/modals";
@import "bootstrap/tooltip";
@import "bootstrap/popovers";
@import "bootstrap/carousel";

// Bootstrap utility classes
// -----------------------------------------------------------------------------
@import "bootstrap/utilities";
@import "bootstrap/responsive-utilities";

// FontAwesome
// -----------------------------------------------------------------------------
@import "font-awesome";
@import "~font-awesome-sass/assets/stylesheets/font-awesome-sprockets";

// Bootswatch
// -----------------------------------------------------------------------------
@import "bootswatch/bootswatch";

// Radix styles overrides and fixes.
// -----------------------------------------------------------------------------
// Radix styles overrides and fixes.
// -----------------------------------------------------------------------------
@import "radix/admin";
@import "radix/comment";
@import "radix/form";
@import "radix/layout";
@import "radix/maintenance";
@import "radix/modal";
@import "radix/nav";
@import "radix/node";
@import "radix/panel";
@import "radix/structure";
@import "radix/view";

// labs partials
// --------------------------------------------------
@import "base/typography";
@import "layout/_page.scss";
$brand-color: #794C6E;
.domain-spectra-diagnostics{
	
@import "components/_admin_ui.scss";
@import "components/_block.scss";
@import "components/_button.scss";
@import "components/_colorbox.scss";
@import "components/_comment.scss";
@import "components/_field.scss";
@import "components/_footer.scss";
@import "components/_form.scss";
@import "components/_header.scss";
@import "components/_menu.scss";
@import "components/_node.scss";
@import "components/_panel.scss";
@import "components/_slick.scss";
@import "components/_structure.scss";
@import "components/_term.scss";
@import "components/_user.scss";
@import "components/_view.scss";
}
$brand-color: #4F8ABE;
.domain-spectra-laboratories{
	
@import "components/_admin_ui.scss";
@import "components/_block.scss";
@import "components/_button.scss";
@import "components/_colorbox.scss";
@import "components/_comment.scss";
@import "components/_field.scss";
@import "components/_footer.scss";
@import "components/_form.scss";
@import "components/_header.scss";
@import "components/_menu.scss";
@import "components/_node.scss";
@import "components/_panel.scss";
@import "components/_slick.scss";
@import "components/_structure.scss";
@import "components/_term.scss";
@import "components/_user.scss";
@import "components/_view.scss";
}
@import "pages/_customer_login.scss";
@import "pages/_home.scss";
@import "pages/_search_pages.scss";
@import "pages/_supply_order_form.scss";
@import "pages/_test_menu.scss";
@import "pages/_user.scss";
