// Mixins
// -----------------------------------------------------------------------------
@mixin menu-icon($url) {
    background-image: url($url);
    background-repeat: no-repeat;
    background-position: left center;
}

@mixin fullscreen() {
    width: 100vw;
    max-width: 100vw;
    max-height: none;

    @media (min-width: $screen-lg-min) {
        transform: translateX(-50vw) translateX(640px);
    }

    @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
        transform: translateX(-50vw) translateX(485px);
    }

    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        transform: translateX(-50vw) translateX(375px);
    }

    @media (max-width: $screen-xs-max) {
        transform: translateX(0);
    }
}

@mixin fullscreen-centered-y() {
    width: 100vw;
    max-width: 100vw;
    max-height: none;
    position: absolute;
    left: 0;
    top: 50%;

    @media (min-width: $screen-lg-min) {
        transform: translateX(-50vw) translateX(640px) translateY(-50%);
    }

    @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
        transform: translateX(-50vw) translateX(485px) translateY(-50%);
    }

    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        transform: translateX(-50vw) translateX(375px) translateY(-50%);
    }

    @media (max-width: $screen-xs-max) {
        transform: translateX(-15px) translateY(-50%);
    }
}

@mixin wave-top-padding-small() {
    @media (min-width: $screen-lg-min) {
        padding-top: 70px;
    }

    @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
        padding-top: 50px;
    }

    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        padding-top: 30px;
    }

    @media (max-width: $screen-xs-max) {
        padding-top: 50px;
    }

    @media (max-width: $screen-xs-min) {
        padding-top: 30px;
    }
}

@mixin wave-top-padding-big() {
    @media (min-width: $screen-lg-min) {
        padding-top: 105px;
    }

    @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
        padding-top: 85px;
    }

    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        padding-top: 60px;
    }

    @media (max-width: $screen-xs-max) {
        padding-top: 50px;
    }

    @media (max-width: $screen-xs-min) {
        padding-top: 30px;
    }
}

@mixin wave-bottom-padding-small() {
    @media (min-width: $screen-lg-min) {
        padding-bottom: 70px;
    }

    @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
        padding-bottom: 50px;
    }

    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        padding-bottom: 30px;
    }

    @media (max-width: $screen-xs-max) {
        padding-bottom: 50px;
    }

    @media (max-width: $screen-xs-min) {
        padding-bottom: 30px;
    }
}

@mixin wave-bottom-padding-big() {
    @media (min-width: $screen-lg-min) {
        padding-bottom: 105px;
    }

    @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
        padding-bottom: 85px;
    }

    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        padding-bottom: 60px;
    }

    @media (max-width: $screen-xs-max) {
        padding-bottom: 50px;
    }

    @media (max-width: $screen-xs-min) {
        padding-bottom: 30px;
    }
}
