// Styles for button.
// -----------------------------------------------------------------------------
.btn {
    position: relative;
    color: white;
    background-color: $orange;
    border-radius: 100px;
    padding: 4.5px 30px 4.5px 15px;
    border: none;
    font-size: 16px;

    &:after {
        content: " ";
        background: url("../../assets/images/svg/chevron-right.svg") no-repeat;
        width: 6px;
        height: 10px;
        position: absolute;
        top: 50%;
        @include transform(translateY(-50%));
        right: 10px;
        display: inline-block;
    }

    &:hover {
        color: white;
        background-color: $orange-light;
    }

    &:active, &:focus {
        color: $grey-light;
        background-color: $orange-dark;
        @include box-shadow(unset);

        &:after {
            background: url("../../assets/images/svg/chevron-right-grey-light.svg") no-repeat;
        }
    }

    &.btn-cta {
        min-width: 188px;
    }

    &.btn-lg {
        font-size: 18px;
        padding-top: 2.5px;
        padding-bottom: 4.5px;
        font-weight: 300;
    }

    &.btn-primary {
      border: none;
    }
}

input.btn {
  padding: 4.5px 15px;
}

.print-btn {
  padding-top: 20px;
  font-size: 12px;
  width: 74px;
  height: 74px;
  border: 1px solid $grey;
  border-radius: 50%;
  background: url("../../assets/images/svg/print-icon.svg") no-repeat;
  background-color: white;
  background-position: center 10px;
  margin-top: 10px;

  @media (min-width: $screen-sm-min) {
    position: fixed;
    right: 10px;
    bottom: 10px;
    z-index: 9999;
  }
}
