// Page Layout
// -----------------------------------------------------------------------------

$grey-ultra-light: #F5F5F5;
$grey-light: #D6D6D6;
$grey: #808285;
$grey-strong: #5a5b5c;
$grey-dark: #4A4A4A;

$orange-light: #FF8E1C;
$orange: #F37321;
$orange-dark: #EB8218;

$blue-light: #4A90E2;
$blue: #4F8ABE;

$violet-light: #B30838;
$violet: #794C6E;

#admin-menu {
  z-index: 9999;
}

body {
  overflow-x: hidden;
  font-size: 16px;
  @media (max-width: $screen-md-max) {
    font-size: 14px;
  }

  &.noscroll {
    header {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      .navbar-collapse.collapse.in {
        height: calc(100vh - 54px) !important;
      }
    }
  }

  &.logged-in {
    .customer-login {
      display: none !important;
    }
  }
}

@media print {
  header, footer  {
    display: none;
  }
}


.noscroll {
  overflow-y: hidden;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6, p, li, span, table, label {
  color: $grey;
  font-weight: 400;
  letter-spacing: 0px;
  margin-top: 0;
}

.bg-content, .node-hero-video {
  h1, h1 span {
    font-size: 48px;
    font-weight: 700;
    letter-spacing: 1px;

    /*@media (max-width: $screen-xs-min) {
      font-size: 24px;
    }*/
  }
}

h1 {
  text-align: center;

  &.title {
    margin-top: 30px;
  }
}

h1, h1 span,
h2, h2 span {
  font-size: 50px;

  @media (max-width: $screen-xs-max) {
    font-size: 24px;
    font-weight: 500;
  }
}

h3, h3 span {
  font-size: 36px;
  font-weight: 500;

  @media (max-width: $screen-xs-max) {
    font-size: 22px;
  }
}

h4, h4 span {
  font-size: 24px;
  font-weight: 500;

  @media (max-width: $screen-xs-max) {
    font-size: 20px;
  }
}

h5, h5 span {
  font-size: 18px;
  font-weight: 500;
}

p {
  margin-bottom: 16px;
  line-height: 20px;
}

strong, strong span {
  color: $grey-strong;
  font-weight: 700;
}

img {
  max-width: 100%;
  height: auto;
  max-height: 100%;
}

a {
  color: $orange;

  &:hover {
    color: $orange-light;
    text-decoration: none;
  }

  &:active {
    color: $grey-dark;
    text-decoration: none;
  }
}

ul {
  padding-left: 25px;

  &.orange {
    li {
      color: $orange;

      p {
        color: $grey;
      }
    }
  }

  &.no-bullets {
    li {
      list-style-type: none;
    }
  }

  &.spaced {
    li {
      margin-bottom: 20px;
    }
  }

  &.carret {
    padding-left: 0;

    li {
      list-style-type: none;
      padding-left: 20px;
      position: relative;

      &:before {
        content: " ";
        background: url(../../assets/images/svg/chevron-right-orange.svg) no-repeat;
        width: 6px;
        height: 10px;
        position: absolute;
        top: 6px;
        left: 5px;

        @media (max-width: $screen-md-max) {
          top: 4px;
        }
      }
    }
  }
}
