.slick {
	position: relative;
	margin-top: 30px;
	margin-bottom: 30px;

	.slick__slide:focus {
		outline: initial;
	}

	.slick-arrow {
		position: absolute;
		top: 50%;
		background: none;
		border: none;
		color: $orange;
		font-size: 60px;
		cursor: pointer;
        padding: 20px;

		&.slick-disabled {
			display: none !important;
		}

		&.slick-prev {
			left: 30px;
            padding-left: 0;
			transform: translate(-100%, calc(-50% - 2px));
		}

		&.slick-next {
			right: 30px;
            padding-right: 0;
            transform: translate(100%, calc(-50% - 2px));
		}
	}
}
