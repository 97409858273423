// Styles for Nodes
// -----------------------------------------------------------------------------
.node {
    &.node-hero-video {
        position: relative;

        @media (max-width: $screen-xs-max) {
            overflow-x: hidden;
            width: calc(100vw);
            transform: translateX(-15px);
            padding-left: 15px;
            padding-right: 15px;
        }

        .field-name-field-hero-video {
            width: 100.1vw;
            @media (min-width: $screen-lg-min) {
                transform: translateX(-50vw) translateX(625px);
            }

            @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
                transform: translateX(-50vw) translateX(470px);
            }

            @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
                transform: translateX(-50vw) translateX(360px);
            }

            @media (max-width: $screen-xs-max) {
                width: 1405px;
                transform: translateX(-50vw);

            }
        }

        .field-name-body {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            width: 100%;
            p, p span {
              text-align: center;
              font-size: 22px;
              margin-left: auto;
              margin-right: auto;
            }

            @media (max-width: $screen-xs-max) {
                /*top: 50%;
                transform: translate(-50%, -50%)*/

                /*h1, h1 span{
                    font-size: 24px !important;
                }*/


/*
                .btn {
                    margin-top: 155px;
                }*/
            }

            h1, p, span {
                color: white;
            }
        }
    }

    &.entity-view-mode--slick {
      padding-left: 15px;
      padding-right: 15px;

      h2 {
        display: none;
      }

      img {
        margin-left: auto;
        margin-right: auto;
      }
    }
}
