body.page-search-pages {
  #messages {
    .alert.alert-danger {
      background-color: $grey-dark;
      border: 1px solid #404040;
      color: white;

      button.close {
        opacity: 1;

        &:hover, &:focus {
          color: $grey;
          text-shadow: 0 1px 0 $grey;
        }

        &:active {
          color: white;
        }
      }
    }
  }
}
