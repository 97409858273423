// Styles for panels and panes.
// -----------------------------------------------------------------------------
#content [class*='panels-bootstrap-row-']:not([class*='node']) {
  &:not(.row-nospace):not(.row-nospace-top) {
    padding-top: 60px;
  }

  &:not(.row-nospace):not(.row-nospace-bottom) {
    padding-bottom: 60px;
  }

  &.row-space, &.row-space-top {
    margin-top: 60px;
  }

  &.row-space, &.row-space-bottom {
    margin-bottom: 60px;
  }
}

.panel-bootstrap {
  .section-hero {
    .bg-image {
      .bg-content {
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);

        h1, h1 span, p, p span {
          color: white;
        }

        p, p span {
          font-size: 22px;
        }
      }
    }
  }
  .bg-image, .bg-color {
    .overlay-wave {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100vw;
      height: 100%;
      z-index: 5;
      background-repeat: no-repeat;
      background-position: bottom;
      background-size: 100vw;
      mix-blend-mode: multiply;

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        /*background-image: none !important;
        background-color: #000 !important;*/
        opacity: 0.3;
      }
    }


    svg.wave {
      position: absolute;
      left: 0;
      z-index: 10;
      @include fullscreen();



	  @media (max-width: $screen-sm-min) {
        transform: translateX(0px)!important;
      }

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        // Poor IE11 isn't good at math. He can't seem to figure the height of the SVG so we calculate it for him
        height: calc(100vw * 70.0 /1366.0);
      }

      &.wave-top {
        top: -3px;
      }

      &.wave-bottom {
        bottom: -3px;
      }
    }

    .bg-content {
      z-index: 15;
      @include clearfix();
    }
  }

  .bg-color{
    color:  white;
    padding-top: 50px;
    padding-bottom: 50px;
    @include fullscreen();

    &[class*="bg_wave"] {
      position: relative;

      svg.wave {
        width: 100vw;

        @media (min-width: $screen-sm-min) {
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }

    &[class*="bg_wave_top"] {
      padding-top: 0;
    }

    &[class*="bg_wave_bottom"] {
      padding-bottom: 0;

      .bg-content {
        @include wave-bottom-padding-big();
      }
    }

    .bg-content {
      margin-left: auto;
      margin-right: auto;

      @media (min-width: $screen-sm-min) {
        width: $container-sm;
      }

      @media (min-width: $screen-md-min) {
        width: $container-md;
      }

      @media (min-width: $screen-lg-min) {
        width: $container-lg;
      }

      h1,h2,h3,h4,h5,p,li{
        color:  white;

        span  {
          color: white;
        }
      }
    }
  }

  .bg-image {
    position: relative;

    picture {
      display: block;
      position: relative;
      @include fullscreen();

	  @media (max-width: $screen-sm-min) {
        transform: translateX(0px)!important;
      }

      img {
        width: 100vw;
        max-width: 100vw;
        z-index: -1;
      }

      .overlay-mobile {
        @media (min-width: $screen-sm-min) {
          display: none;
        }

        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0.75;
        mix-blend-mode: multiply;
      }
    }

    .bg-content {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;

      @include clearfix();
    }

    &.bg-svg {
      padding-top: 50px;
      padding-bottom: 50px;

      @media (min-width: 1401px) {
        padding-top: 100px;
        padding-bottom: 100px;
      }

      @media (min-width: 1650px) {
        padding-top: 150px;
        padding-bottom: 150px;
      }

      @media (min-width: 1921px) {
        padding-top: 200px;
        padding-bottom: 200px;
      }

      & > img {
        @include fullscreen-centered-y();
      }

      .bg-content {
        position: static;
        left: 0;
        transform: none;
      }
    }

    /* Adjusting the admin editor drop down to offset the padding top applied */
    .contextual-links-wrapper{
      top: 102px;
    }
  }

  [class*="bg_wave_top"] {
    .panels-bootstrap-column:first-child {
      @include wave-top-padding-big();
    }
  }


  .bg_wave_top_left .bg_wave_left_col.panels-bootstrap-column,
  .bg_wave_top_right .bg_wave_right_col.panels-bootstrap-column {
    @include wave-top-padding-small();
  }

  .bg_wave_top_left .bg_wave_right_col.panels-bootstrap-column,
  .bg_wave_top_right .bg_wave_left_col.panels-bootstrap-column {
    @include wave-top-padding-big();
  }

}
