// Helpers
// -----------------------------------------------------------------------------
.gradiant-white-overlay{
	background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,0.65) 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(255,255,255,0.65)), color-stop(100%,rgba(255,255,255,0))); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,0.65) 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,0.65) 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,0.65) 100%); /* IE10+ */
	background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,0.65) 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6FFFFFF', endColorstr='#00FFFFFF',GradientType=0 ); /* IE6-9 */
}

.gradiant-diagnostics-overlay{
	background: -moz-linear-gradient(top, rgba(121,76,110,0) 0%, rgba(121,76,110,0.65) 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(121,76,110,0.65)), color-stop(100%,rgba(121,76,110,0))); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top, rgba(121,76,110,0) 0%,rgba(121,76,110,0.65) 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top, rgba(121,76,110,0) 0%,rgba(121,76,110,0.65) 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(top, rgba(121,76,110,0) 0%,rgba(121,76,110,0.65) 100%); /* IE10+ */
	background: linear-gradient(to bottom, rgba(121,76,110,0) 0%,rgba(121,76,110,0.65) 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6794c6e', endColorstr='#00794c6e',GradientType=0 ); /* IE6-9 */
}