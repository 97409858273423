#page-supply-order-form {
  @media print {
    .row {
      padding: 0 !important;
    }
  }

  form {
    .form-actions {
      text-align: center;
      display: block;
      clear: both;
    }

    fieldset.webform-component--float {
      padding: 15px;
      margin: 0;
      border: 0;
      box-shadow: none;
      width: calc(100%);

      @media print {
        padding: 15px 0;
      }

      & > .fieldset-wrapper {
        padding: 0;

        fieldset {
          margin: 0 0 15px 0;
          width: calc(33% - 15.5px);

          @media screen and (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
            width: calc(50% - 23.5px);
          }

          @media screen and (max-width: $screen-xs-max) {
            width: calc(100% - 30px);
          }

          @media print {
            width: 350px;
            margin-left: 15px;

            &:nth-child(2n+1) {
              clear: both;
            }
          }

          .panel-title {
            color: $orange;
          }

          .form-item {
            position: relative;
            margin-bottom: 10px;

            &:last-child {
              margin-bottom: 0px;
            }

            label {
              display: inline-block;
              max-width: calc(100% - 110px);
              margin-bottom: 0;
              font-size: 15px;
              color: $grey-strong;
              font-weight: 500;
            }

            span.field-prefix {
              position: absolute;
              top: 4px;
              right: 65px;

              color: $grey-strong;
              font-weight: 500;
              font-size: 12px;
            }

            input.form-number {
              position: absolute;
              top: 0;
              right: 0;
              width: 50px;
              height: auto;
              padding: 5px 0 5px 5px;
              font-size: 12px;
            }

            div.description {
              max-width: calc(100% - 110px);
              font-size: 12px;
            }

            &[class*="other-supplies"] {
              &.webform-component-textfield {
                float: none;
                width: calc(100% - 110px);

                input {
                  height: auto;
                  font-size: 12px;
                }
              }

              &.webform-component-number {
                .field-prefix {
                  top: -37px;
                }

                input {
                  top: -41px;
                }
              }
            }
          }
        }
      }
    }
  }
}
