#page-customer-login {
  .welcome {
    margin-bottom: 30px;
  }

  .customer-links {
    a {
      display: inline-block;
      margin-bottom: 10px;
      vertical-align: top;

      img {
        margin-right: 5px;

        &[src*=".svg"] {
          width: 220px;
          margin-left: 2px;
          margin-right: 10px;
          padding-left: 10px;
          padding-right: 10px;
          border: 1px solid #C8C8C8;
        }
      }

      &.fmc4me {
        img {
          padding: 0;
          border: none;
        }
      }
    }

    p {
      display: inline-block;

      @media (min-width: $screen-xs-min) {
        max-width: calc(100% - 240px);
      }
    }

    &.fmc-login {
      margin-top: 100px;
    }
  }
}
