.domain-spectra-laboratories {
  #page-test-menu {
    .section-2 {
      .pane-content {
        text-align: center;

        img {
          margin-top: 40px;
          margin-bottom: 30px;
        }

        h5 {
          margin-bottom: 15px;
        }

        p {
          font-weight: 500;
        }

        ul:not(.contextual-links) {
          display: inline-block;
          text-align: left;
          padding: 0;

          li {
            list-style-type: none;
            color: $grey;
          }
        }
      }
    }

    .section-3 {
      .panels-bootstrap-column:first-child {
        @media (min-width: $screen-sm-min) {
          margin-bottom: 65px;
        }

        h3 {
          margin-top: 30px;
          margin-bottom: 30px;
        }

        @media (max-width: $screen-xs-max) {
          img {
            display: none;
          }

          h3, p {
            text-align: left;
          }
        }
      }

      ul {
        padding: 0;

        li {
          list-style-type: none;
        }
      }
    }

    .section-4 {
      h4, h4 span {
        margin-bottom: 40px;
      }
    }
  }
}
