// Styles for Forms
// -----------------------------------------------------------------------------

form {
  @include make-row();

  fieldset {
    @include make-xs-column(12);
    width: calc(100% - 30px);
    padding: 0;
    margin-top: 15px;
    margin-left: 15px;

    legend {
      .panel-title {
        color: $grey-strong;
        font-weight: 500;
      }
    }
  }

  .form-item {
    &.form-type-radio {
      display: block;
    }

    &.webform-component-textarea {
      @include make-xs-column(12);
    }

    &.webform-component--leave-empty {
      display: none !important;
    }

    .description {
      color: $grey;
    }

    &.form-checkboxes, &.form-type-checkbox {
      clear: both;
      display: block;
      width: auto;
      margin-left: 15px;
      margin-right: 15px;
    }
  }

  .form-actions {
    @include make-xs-column(12);
    margin-top: 0;
  }
}

.col-md-12, .col-lg-12 {
  form {
    .form-item {
      &.form-type-textfield, &.webform-component-textfield,
      &.form-type-password, &.webform-component-password,
      &.webform-component-email,
      &.webform-component-select,
      &.webform-component-radios,
      &.webform-component-markup {
        @include make-xs-column(12);
        @include make-sm-column(6);
        @include make-md-column(4);
        @include make-lg-column(3);
      }
    }
  }
}

.col-md-8, .col-lg-8,  {
  form {
    .form-item {
      &.form-type-textfield, &.webform-component-textfield,
      &.form-type-password, &.webform-component-password,
      &.webform-component-email,
      &.webform-component-select,
      &.webform-component-radios,
      &.webform-component-markup{
        @include make-xs-column(12);
        @include make-sm-column(6);
        @include make-md-column(6);
        @include make-lg-column(6);
      }
    }
  }
}
.g-recaptcha{
	display: inline-block;
	margin-left: 15px;
}
