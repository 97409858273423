// Styles for admin UI.
// -----------------------------------------------------------------------------


//adjust placment of cogwheel on slick slide view to prevent the last item to not be editable
.view-slick > .contextual-links-wrapper {
	margin-top: -30px;
}
.pane-views-panes > .contextual-links-wrapper{
	margin-top: -15px;
}

#messages {
  animation: blinker .4s linear;
  animation-iteration-count: 2;
  margin-top: 10px;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}