.domain-spectra-laboratories {
  #page-home {
    .section-1 {
      .col-xs-12 {
        float: none;

        @media (max-width: $screen-xs-max) {
          h2, h2 span {
            font-size: 18px !important;
          }

          img {
            display: none;
          }

          p, p span {
            font-size: 14px !important;
            font-weight: 300;
          }
        }
      }

      .col-sm-4 {
        .img-container {
          height: 42px;
          line-height: 42px;
          margin-top: 50px;
          margin-bottom: 13px;

          img {
            vertical-align: bottom;
          }
        }

        @media (min-width: $screen-sm-min) {
          &:not(:last-child) {
            .pane-content {
              position: relative;

              &:after {
                content: '';
                height: 100%;
                border-right: 1px solid $grey;
                position: absolute;
                top: 0;
                right: -15px;
              }
            }
          }
        }
      }
    }

    .section-2 {
      .bg-content {
        .pane-content {
          @media (max-width: $screen-xs-max) {
            max-width: 350px;
            margin-left: auto;
            margin-right: auto;
          }

          h3 {
            color: white;

            @media (max-width: $screen-xs-max) {
              font-size: 18px;
              text-align:center;
            }
          }
        }
      }
    }

    .section-3 {
      .section-3-text {
        @media (max-width: $screen-xs-max) {
          h2, h2 span {
            font-size: 18px !important;
          }

          img {
            display: none;
          }

          p, p span {
            font-size: 14px !important;
          }
        }
      }

      h5 {
        margin-top: 15px;

        a {
          color: $grey;
        }
      }

      p {
        a {
          font-size: 14px;
          color: $blue;
        }
      }

      a {
        text-shadow: 1px 1px 1px white;
        background-color: rgba(255, 255, 255, 0.3);
        border-radius: 50px;

        &:hover {
          color: $orange !important;

          span {
            color: $orange !important;
          }
        }
      }
    }

    .section-4,
    .section-6 {
      .bg-image {
        .bg-content {
          .pane-content {
            @media (max-width: $screen-xs-max) {
              max-width: 350px;
              margin-left: auto;
              margin-right: auto;
            }

            h3 {
              color: $blue;
              font-size: 36px;
              font-weight: 500;
            }

            p {
              font-size: 18px;
            }

            @media (max-width: $screen-sm-max) {
              h3 {
                font-size: 24px;
              }

              p {
                font-size: 14px;
                font-weight: 500;
              }
            }

            @media (max-width: $screen-xs-max) {
              h3, p {
                text-align: center;
                color: white;
              }
            }
          }
        }
      }
    }

    .section-5 {
      position: relative;
      min-height: 225px;

      @media (max-width: $screen-xs-max) {

        img {
          /*opacity: 0.35;*/
          position: relative;
          z-index: -1;
          display: block;
          margin: 0 auto;
        }
      }

      .section-5-text {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 50%;

        @media (max-width: $screen-xs-max) {
          top: auto;
          bottom: 0;
          transform: translateX(-50%);
          width: 100%;
          max-width: 475px;

          h3, p {
            text-align: center;
          }

          p, p span {
            font-size: 14px !important;
          }
        }

        h3, h3 span {
          @media (max-width: $screen-sm-max) {
            font-size: 24px;
          }
        }
      }
    }

    .section-6 {
      p {
        color: white;
      }
    }

    .section-2, .section-4, .section-6 {
      .bg-content {
        @media (max-width: $screen-xs-max) {
          top: 50%;
          transform: translateY(-50%);

          .panel-pane {
            padding: 0;
          }
        }
      }
    }
  }

}

.domain-spectra-diagnostics {
  #page-home {
    .section-1 {
      p span {
          display: inline-block;
          max-width: 600px;
        }
    }

    .section-2 {
      position: relative;
      min-height: 225px;

      img {
       /* max-height: 300px;*/
       width: 100%;

        @media (max-width: $screen-xs-max) {
          /*opacity: 0.35;*/
          position: relative;
          z-index: -1;
          display: block;
          margin: 0 auto;
        }
      }

      .section-2-text {
      
        p span {
          margin-top: 20px;
          margin-bottom: 15px;
        }

        @media (min-width: $screen-lg-min) {
          padding-top: 150px;
        }

        @media (max-width: $screen-xs-max) {
          z-index: 1;
          position: absolute;
          bottom: 25px;
          left: 50%;
          transform: translateX(-50%);
          width: 100%;
          max-width: 475px;

          h3, p {
            text-align: center;
          }

          p, p span {
            font-size: 14px !important;
          }
        }

        h3, h3 span {
          @media (max-width: $screen-sm-max) {
            font-size: 24px;
          }
        }
      }
    }

    .section-3-slick {
      .bg-image {
        & > img {
          top: 45%;
        }

        h5 {
          margin-top: 15px;

          a {
            color: $grey;
          }
        }

        p {
          a {
            font-size: 14px;
            color: $violet;
          }
        }

        a {
          text-shadow: 1px 1px 1px white;
          background-color: rgba(255, 255, 255, 0.3);
          border-radius: 50px;

          &:hover {
            color: $orange !important;

            span {
              color: $orange !important;
            }
          }
        }
      }
    }
  }
}
